import { createSlice } from '@reduxjs/toolkit'

const cartItems = localStorage.getItem('cartItems')
  ? JSON.parse(localStorage.getItem('cartItems'))
  : [];

const initialState = {
    isCartOpen: false,
    isSidebarOpen: false,
    // cart: [],
    items: [],
    cart: cartItems || []
}

export const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        setItems: (state, action) => {
            state.items = action.payload
        },
        
        addToCart: (state, action) => {
            let itemIndex = state.cart.filter((item) => item.id === action.payload.item.id)[0]
            if (itemIndex) {
                state.cart = state.cart.map((item) => {
                    if (item.id === action.payload.item.id) {
                        item.count += action.payload.item.count
                    }
                    return item
                })
            }
            else {
                state.cart = [...state.cart, action.payload.item]
            }
            localStorage.setItem('cartItems', JSON.stringify(state.cart));
        },
        
        removeFromCart: (state, action) => {
            state.cart = state.cart.filter((item) => item.id !== action.payload.id)
            localStorage.setItem('cartItems', JSON.stringify(state.cart));
        },
        
        updateItemCount: (state, action) => {
            if (action.payload.count <= 0) {
                state.cart = state.cart.filter((item) => item.id !== action.payload.id)
                localStorage.setItem('cartItems', JSON.stringify(state.cart));
            } else {
                state.cart = state.cart.map((item) => {
                    if (item.id === action.payload.id) {
                        item.count = action.payload.count
                        item.attributes.inventory.data.attributes.availableQuantity = action.payload.availableQuantity
                    }
                    return item
                })
                localStorage.setItem('cartItems', JSON.stringify(state.cart));
            }
        },

        increaseCount: (state, action) => {
            state.cart = state.cart.map((item) => {
                if (item.id === action.payload.id) {
                    item.count++
                }
                return item
            })
            localStorage.setItem('cartItems', JSON.stringify(state.cart));
        },
        
        decreaseCount: (state, action) => {
            state.cart = state.cart.map((item) => {
                if (item.id === action.payload.id && item.count > 1) {
                    item.count--
                }
                return item
            })
            localStorage.setItem('cartItems', JSON.stringify(state.cart));
        },
        
        emptyCart: (state) => {
            state.cart = []
            localStorage.setItem('cartItems', JSON.stringify(state.cart))
        },

        setIsCartOpen: (state) => {
            state.isCartOpen = !state.isCartOpen
        },

        setIsSidebarOpen: (state) => {
            state.isSidebarOpen = !state.isSidebarOpen
        }
    }
})

export const {
    setItems,
    emptyCart,
    addToCart,
    removeFromCart,
    increaseCount,
    decreaseCount,
    setIsCartOpen,
    setIsSidebarOpen,
    updateItemCount
} = cartSlice.actions

export default cartSlice.reducer