import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { IconButton, Box, Typography, Button, Tabs, Tab, Alert } from '@mui/material'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { shades } from '../../theme'
import { addToCart } from '../../state'
import { Link, useParams } from 'react-router-dom'
import Item from '../../components/Item'
import { Topic } from '@mui/icons-material'




const ItemDetails = () => {
    const dispatch = useDispatch()
    const { itemId } = useParams()
    const [value, setValue] = useState('description')
    const [item, setItem] = useState(null)
    const [count, setCount] = useState(0)
    const [availableQuantity, setAvailableQuantity] = useState(0)
    const [isSoldOut, setIsSoldout] = useState(true)
    
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    
    async function getItem() {
        const item = await fetch(
            `${process.env.REACT_APP_SERVER_BASE_URL}/api/items/${itemId}?populate=image&populate=inventory`,
            { method: 'GET' }
        )
        const itemJson = await item.json()
        setItem(itemJson.data)
    }
        
    useEffect(() => {
        getItem()
        setAvailableQuantity(item?.attributes?.inventory?.data?.attributes?.availableQuantity)
        setIsSoldout(!availableQuantity || availableQuantity == 0 || item?.attributes?.quantityMultiplier > availableQuantity)
        setCount(isSoldOut ? 0 : 1)
        console.log('IN!', item?.id, 'availableQuantity', availableQuantity, 'isSoldOut', isSoldOut)
    }, [itemId, item?.id, availableQuantity, isSoldOut])

    return (
        <>
            {item && <Box width='80%' m='80px auto'>
                <Box display='flex' flexWrap='wrap' columnGap='40px'>
                    {/* IMAGES */}
                    <Box flex='1 1 40%' mb='40px'>
                        <img
                            alt={item?.name}
                            width='100%'
                            height='100%'
                            src={item?.attributes?.image?.data?.attributes?.url}
                            style={{ objectFit: 'contain' }}
                        />
                    </Box>

                    {/* ACTIONS */}
                    <Box marginTop='25px' flex='1 1 50%' mb='40px'>
                        {/* <Box display='flex' justifyContent='space-between'> */}
                        <Box><Link to='/shop'>Shop</Link></Box>
                            {/* <Box>Prev Next</Box> */}
                        {/* </Box> */}

                        <Box m='40px 0 25px 0'>
                            <Typography variant='h3'>{item?.attributes?.name}</Typography>
                            <Typography fontSize='24px'>€{item?.attributes?.price.toFixed(2)}</Typography>
                            <Typography sx={{ mt: '20px' }} fontSize='16px'>
                                {item?.attributes?.shortDescription}
                            </Typography>
                        </Box>
                        <Box display='flex' alignItems='center' minHeight='50px'>
                            <Box
                                display='flex'
                                alignItems='center'
                                border={`1.5px solid ${shades.neutral[300]}`}
                                mr='20px'
                                p='2px 5px'
                            >
                                <IconButton
                                    onClick={() => setCount(Math.max(count - 1, 1))}
                                    disabled={isSoldOut}
                                >
                                    <RemoveIcon />
                                </IconButton>
                                <Typography sx={{ p: '0 5px' }}>{count}</Typography>
                                <IconButton
                                    onClick={() => setCount(count + 1)}
                                    disabled={(count+1) * item?.attributes?.quantityMultiplier > availableQuantity || isSoldOut}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Box>

                            <Button
                                sx={{
                                    backgroundColor: '#222222',
                                    color: 'white',
                                    borderRadius: 0,
                                    minWidth: '150px',
                                    padding: '10px 40px',
                                    "&.Mui-disabled": {
                                        background: "#DC143C",
                                        color: "white"
                                    }
                                }}
                                onClick={
                                    availableQuantity > 0 ? () => dispatch(addToCart({ item: { ...item, count } }))
                                    : () => null
                                }
                                disabled={isSoldOut}
                            >
                                {isSoldOut ? "SOLD OUT" : "ADD TO CART"}
                            </Button>
                        </Box>
                        {isSoldOut && <Box paddingTop='10px'><Alert severity='info' sx={{fontSize:'15px'}}>Producto terminado, vuelve mañana!</Alert></Box>}

                        {/* <Box>
                            <Box m='20px 0 5px 0' display='flex'>
                                <FavoriteBorderOutlinedIcon />
                                <Typography sx={{ ml: '5px' }}>ADD TO WISHLIST</Typography>
                            </Box>
                            <Typography>CATEGORIES: {item?.attributes?.category}</Typography>
                        </Box> */}
                        {/* INFORMATION */}
                        <Box m='20px 0'>
                            <Tabs value={value} onChange={handleChange}>
                                <Tab label='DESCRIPTION' value='description' />
                                {/* <Tab label='REVIEWS' value='reviews' /> */}
                            </Tabs>
                        </Box>
                        <Box display='flex' flexWrap='wrap' gap='15px'>
                            {value === 'description' && (
                                <Typography sx={{ whiteSpace: 'pre-line'}} fontSize='16px'>{item?.attributes?.longDescription}</Typography>
                            )}
                            {/* {value === 'reviews' && <div>reviews</div>} */}
                        </Box>
                    </Box>
                </Box>
                
                
                {/* RELATED ITEMS
                <Box mt='50px' width='100%'>
                    <Typography variant='h3' fontWeight='bold'>
                        Related Products
                    </Typography>
                    <Box
                        mt='20px'
                        display='flex'
                        flexWrap='wrap'
                        columnGap='1.33%'
                        justifyContent='space-between'
                    >
                        {items.slice(0,4).map((item, i) => {
                            <Item key={`${item.name}-${i}`} item={item} />
                        })}
                    </Box>
                </Box> */}
            </Box>}
        </>
    )
}

export default ItemDetails