import { Mail, Instagram, WhatsApp } from "@mui/icons-material"
import { useTheme, Box, Typography, useMediaQuery, IconButton } from "@mui/material"
import { useNavigate } from "react-router-dom"


const Footer = () => {
    const navigate = useNavigate()
    const isNonMobile = useMediaQuery("(min-width:600px)")

    const openWhatsappLink = () => {
        // Construct the Google Drive link
        // const driveLink = `https://drive.google.com/file/d/${fileId}/view`;
        const driveLink = `https://wa.me/+34691726328`;
    
        // Open the link in a new tab
        window.open(driveLink, '_blank');
    };

    const openEmailLink = () => {
        // Construct the Google Drive link
        // const driveLink = `https://drive.google.com/file/d/${fileId}/view`;
        const driveLink = `mailto:hola@tartufinoes.com`;
    
        // Open the link in a new tab
        window.open(driveLink, '_blank');
    };

    const openInstagramLink = () => {
        // Construct the Google Drive link
        // const driveLink = `https://drive.google.com/file/d/${fileId}/view`;
        const driveLink = `https://www.instagram.com/tartufinoes/`;
    
        // Open the link in a new tab
        window.open(driveLink, '_blank');
    };

    const {
        palette: { neutral },
    } = useTheme()
    return <Box mt='70px' p='40px 0' backgroundColor={neutral.light}>
        <Box
            width='80%'
            margin='auto'
            display='flex'
            justifyContent='space-between'
            flexWrap='wrap'
            rowGap='30px'
            // columnGap='clamp(20px, 30px, 40px)'
            columnGap='10px'
        >
            <Box
                // width='clamp(20%, 30%, 40%)'
                width='40%'
            >
                <Typography
                    variant='h3'
                    // fontWeight='bold'
                    fontFamily='Gluten'
                    mb='15px'
                    // color={shades.secondary[500]}
                >
                    TARTUFINO
                </Typography>
                    <div style={{fontSize: '18px'}}>
                    TARTUFINO<br/>
                    Sofia Marchesini<br/>
                    Y9927987M<br/>
                    Calle Castaños 44<br/>
                    Alicante 03001<br/>
                    </div>
            </Box>
            <Box
                width={ isNonMobile ? '20%' : '40%' }
            >
                <Typography
                    sx={{
                        '&:hover': { cursor: "pointer" },
                        textDecoration: 'underline'
                    }}
                    mb='20px'
                    onClick={ () => { navigate('/quiensomos') } }
                >
                    About Us
                </Typography>
                <Typography
                    sx={{
                        '&:hover': { cursor: "pointer" },
                        textDecoration: 'underline'
                    }}
                    mb='20px'
                    onClick={ () => { navigate('/trabajaconnosotros') } }
                >
                    Careers
                </Typography>
                <Typography
                    sx={{
                        '&:hover': { cursor: "pointer" },
                        textDecoration: 'underline'
                    }}
                    mb='20px'
                    onClick={ () => { navigate('/condiciones-de-venta') } }
                >
                    Condiciones de venta
                </Typography>
                <Typography
                    sx={{
                        '&:hover': { cursor: "pointer" },
                        textDecoration: 'underline'
                    }}
                    mb='20px'
                    onClick={ () => { navigate('/metodos-de-pago') } }
                >
                    Mètodos de pago
                </Typography>
                <Typography
                    sx={{
                        '&:hover': { cursor: "pointer" },
                        textDecoration: 'underline'
                    }}
                    mb='20px'
                    onClick={ () => { navigate('/politica-de-devoluciones') } }
                >
                    Politica de devoluciones
                </Typography>
                <Typography
                    sx={{
                        '&:hover': { cursor: "pointer" },
                        textDecoration: 'underline'
                    }}
                    mb='20px'
                    onClick={ () => { navigate('/privacy-policy') } }
                >
                    Política de privacidad
                </Typography>
            </Box>
            <Box
                // width='clamp(20%, 25%, 30%)'
                width={ isNonMobile ? '20%' : '90%'}
            >
                <Typography variant='h4' fontWeight='bold' mb='15px'>
                    Contactos
                </Typography>
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent='left'
                >
                    <IconButton
                        onClick={ () => openWhatsappLink() }
                        sx={{ p: 0 }}
                    >
                        <WhatsApp
                            // fontSize='large'
                            // sx={{ '&:hover': { cursor: "pointer" } }}
                        />
                    </IconButton>
                    <Typography
                        onClick={() => openWhatsappLink()}
                        p='10px 5px'
                        sx={{ '&:hover': { cursor: "pointer" } }}
                        alignSelf='center'
                    >
                        691726328
                    </Typography>
                </Box>
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent='left'
                >
                    <IconButton
                        onClick={ () => openEmailLink() }
                        sx={{ p: 0 }}
                    >
                        <Mail
                            // fontSize='large'
                            // sx={{ '&:hover': { cursor: "pointer" } }}
                        />
                    </IconButton>
                    <Typography
                        onClick={() => openEmailLink()}
                        p='10px 5px'
                        sx={{ '&:hover': { cursor: "pointer" } }}
                        alignSelf='center'
                    >
                        hola@tartufinoes.com
                    </Typography>
                </Box>
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent='left'
                >
                    <IconButton
                        onClick={() => openInstagramLink()}
                        sx={{ p: 0 }}
                    >
                        <Instagram
                            // fontSize='large'
                            // sx={{ '&:hover': { cursor: "pointer" } }}
                        />
                    </IconButton>
                    <Typography
                        onClick={() => openInstagramLink()}
                        p='10px 5px'
                        sx={{ '&:hover': { cursor: "pointer" } }}
                        alignSelf='center'
                    >
                        @tartufinoes
                    </Typography>
                </Box>
            </Box>
        </Box>

    </Box>

}

export default Footer