import { Box, Typography, IconButton, Button, useMediaQuery } from '@mui/material'
import { Carousel } from 'react-responsive-carousel'
import { shades } from '../../theme'

import 'react-responsive-carousel/lib/styles/carousel.min.css'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { useNavigate } from 'react-router-dom'

import videov2 from '../../assets/truf.mp4'


// import
const importAll = (r) =>
    r.keys().reduce((acc, item) => {
        acc[item.replace('./', '')] = r(item)
        return acc
    }, {})

export const heroTextureImports = importAll(
    require.context('../../assets/carousel', false, /\.(png|jpe?g|svg)$/)
)

const carouselWords = ['Comprar trufa online', 'Trufa para restaurantes']


const MainCarousel = ({mailFormRef}) => {
    const isNonMobile = useMediaQuery("(min-width:600px)")
    const navigate = useNavigate()
    // const scrollToMailForm = () => mailFormRef.current.scrollIntoView()

    return (
        <Box
            height='100vh'
            // width='100vw'
            top='30px'
        >
            <Box
                position='absolute'
                top='0'
                left='0'
                width='100%'
                height='100%'
                bgcolor={isNonMobile ? 'rgba(0,0,0,.35)' : 'rgba(0,0,0,0)'}
            >

            </Box>
            <video
                src={videov2}
                autoPlay
                loop
                muted
                style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                }}
            />
            <Box
                position='absolute'
                width='100%'
                height='100%'
                top='0'
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
                color='white'
            >
                <Typography
                    variant='h1'
                    fontWeight='medium'
                    padding='10px'
                    marginBottom='30px'
                    align='center'
                    sx={{textTransform: 'capitalize'}}
                >
                    {/* Sueña grande, come <span style={{fontFamily: 'Gluten'}}>TARTUFINO</span> */}
                    Tu proveedor de trufa.
                </Typography>
                <Button
                    sx={{
                        backgroundColor: 'whitesmoke',
                        color: 'black',
                        borderRadius: "16px",
                        borderColor: '#16100f',
                        border: '1px solid',
                        minWidth: '150px',
                        padding: '10px 40px',
                        fontSize: '28px',
                        fontFamily: 'Gluten',
                        '&:hover': {
                            backgroundColor: "#16100f",
                            color: 'white',
                            borderColor: 'white'
                        }
                    }}
                    onClick={ () => navigate('/shop') }
                >
                    COMPRAR
                </Button>
            </Box>
        </Box>
    )
}

export default MainCarousel