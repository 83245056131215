import { Box } from '@mui/material'
import ShoppingList from './ShoppingList'

const Shop = ({activeTab}) => {
    return (
        <Box width='80%' m='80px auto'>
            <ShoppingList activeTab={activeTab} />
        </Box>
    )
}

export default Shop