import { Box, Card, Link, Typography, useMediaQuery } from '@mui/material'

const Careers = () => {
    const isNonMobile = useMediaQuery('(min-width:600px)')

    const openEmailLink = () => {
        // Construct the Google Drive link
        // const driveLink = `https://drive.google.com/file/d/${fileId}/view`;
        const driveLink = `mailto:hola@tartufinoes.com`;
    
        // Open the link in a new tab
        window.open(driveLink, '_blank');
    };

    return (
        <Box width='80%' m='80px auto'>
            <Box
                m='20px 10px'
                p='20px 0px'
            >
                <Typography
                    variant='h2'
                    align='center'
                    fontFamily='Gluten'
                >
                    TRABAJA CON NOSOTROS
                </Typography>
            </Box>
            <Box align='center'>
                <Card
                    sx={{ 
                        p: '20px 15px',
                        maxWidth: isNonMobile? '50%' : '90%',
                    }}
                    align='left'
                    variant='outlined'
                >
                    <Typography
                        variant='h3'
                    >
                        Posiciones abiertas
                        <ul>
                            <li>Comercial de venta</li>
                        </ul>
                    </Typography>
                </Card>
            </Box>
            <Box
                m='20px 10px'
                p='20px 0px'
            >
                <Typography
                    variant='h3'
                    align='center'
                >
                    Si estas interesado en trabajar con nosotros envíanos tu CV a&nbsp;
                    <Link onClick={() => openEmailLink()} sx={{ '&:hover': { cursor: "pointer" } }}>
                        hola@tartufinoes.com
                    </Link>
                </Typography>
            </Box>
        </Box>
    )
}

export default Careers