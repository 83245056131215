import { Box, Button, IconButton, Typography, Fade, Slide, Alert } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import styled from '@emotion/styled'
import { shades } from '../../theme'
import {
    updateItemCount,
    decreaseCount,
    increaseCount,
    removeFromCart,
    setIsCartOpen
} from '../../state'
import { useNavigate } from 'react-router-dom'
import { loadStripe } from "@stripe/stripe-js"
import { useEffect, useState } from 'react'


const FlexBox = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const stripePromise = loadStripe(
    'pk_live_51OKekGLyMEypsuM4Bn6KcalcbkzKjp1AbwjOaelXVdFk6GkmdTzeaIFzSHjSRSgVKiLyhsUpbFeGRzgLu5ba9aBX00WTJ0Pp2j'
)

const CartMenu = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const cart = useSelector((state) => state.cart.cart)
    const isCartOpen = useSelector((state) => state.cart.isCartOpen)
    const [reloadUtility, setReloadUtility] = useState(false)
    const [availabilityError, setAvailabilityError] = useState(false)

    const totalPrice = cart.reduce((total, item) => {
        return total + item.count * item.attributes.price;
    }, 0)

    const shippingCost = totalPrice > 50 | totalPrice <= 0 ? 0 : 8.0

    async function getItems() {
        const items = await fetch(
            `${process.env.REACT_APP_SERVER_BASE_URL}/api/items?populate=image&populate=inventory`,
            { method: 'GET' }
        )
        const itemsJson = await items.json()
        return itemsJson
    }

    async function checkProductsAvailability() {
        const retrievedItems = await getItems()

        const allProductsAvailable = cart.reduce((available, item) => {
            const retrievedItem = retrievedItems.data.filter(obj => obj.id == item.id)[0]
            const availableQuantity = retrievedItem.attributes.inventory.data.attributes.availableQuantity
            const isCurrentAvailable = item.count * item.attributes.quantityMultiplier <= availableQuantity
            if (!isCurrentAvailable || availableQuantity != item.attributes.inventory.data.attributes.availableQuantity) {
                const count = Math.min(availableQuantity / item.attributes.quantityMultiplier, item.count)
                dispatch(updateItemCount({ id: item.id, count: count, availableQuantity: availableQuantity }))
            } 
            return available && isCurrentAvailable
        }, true)
        setReloadUtility(!reloadUtility)
        return allProductsAvailable
    }

    async function makePayment(
        // values
    ) {
        const stripe = await stripePromise
        const requestBody = {
            products: cart.map(({ id, count }) => ({
                id,
                count,
            })),
            totalPrice: totalPrice
        }

        const response = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/api/orders`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify(requestBody)
        })
        const session = await response.json()
        await stripe.redirectToCheckout({
            sessionId: session.id
        })
    }

    useEffect(() => {
        checkProductsAvailability()
    }, [])

    return (
        <Fade in={isCartOpen} mountOnEnter unmountOnExit>
            <Box // overlay
                backgroundColor='rgba(0, 0, 0, 0.4)'
                position='fixed'
                zIndex={10}
                width='100%'
                height='100%'
                left='0'
                top='0'
                onClick={() => dispatch(setIsCartOpen({}))}
                overflow='auto'
            >
                <Slide in={isCartOpen} direction="left" mountOnEnter unmountOnExit>
                    {/* MODAL */}
                    <Box
                        position='fixed'
                        width='max(370px, 30%)'
                        height='100%'
                        right='0'
                        bottom='0'
                        backgroundColor='white'
                        onClick={(e) => e.stopPropagation()}
                    >
                        <Box padding='30px' overflow='auto' height='100%'>
                            {/* HEADER */}
                            <FlexBox mb='15px'>
                                <Typography fontFamily='Gluten' variant='h2'>CARRO</Typography>
                                <IconButton onClick={() => dispatch(setIsCartOpen({}))}>
                                    <CloseIcon />
                                </IconButton>
                            </FlexBox>

                            {/* CART LIST */}
                            <Box>
                                {cart.map((item) => (
                                    <Box key={`${item.attributes.name}-${item.id}`}>
                                        <FlexBox p='15px 0'>
                                            <Box flex='1 1 40%' paddingRight='10px'>
                                                <img 
                                                    alt={item?.name}
                                                    width='123px'
                                                    height='164px'
                                                    src={item.attributes.image.data.attributes.url}
                                                    style={{ objectFit: 'contain' }}
                                                />
                                            </Box>
                                            <Box flex='1 1 60%'>

                                                {/* ITEM NAME */}
                                                <FlexBox mb='5px'>
                                                    <Typography fontWeight='bold'>
                                                        {item.attributes.name}
                                                    </Typography>
                                                    <IconButton onClick={() => dispatch(removeFromCart({ id: item.id }))}>
                                                        <CloseIcon />
                                                    </IconButton>
                                                </FlexBox>
                                                <Typography>{item.attributes.shortDescription}</Typography>

                                                {/* AMOUNT */}
                                                <FlexBox m='15px 0'>
                                                    <Box
                                                        display='flex'
                                                        alignItems='center'
                                                        border={`1.5px solid ${shades.neutral[500]}`}
                                                    >
                                                        <IconButton
                                                            onClick={() => dispatch(decreaseCount({ id: item.id }))}
                                                        >
                                                            <RemoveIcon />
                                                        </IconButton>
                                                        <Typography>{item.count}</Typography>
                                                        <IconButton
                                                            onClick={() => dispatch(increaseCount({ id: item.id }))}
                                                            disabled={(item.count+1) * item.attributes.quantityMultiplier > item.attributes.inventory.data.attributes.availableQuantity}
                                                        >
                                                            <AddIcon />
                                                        </IconButton>
                                                    </Box>
                                                    {/* PRICE */}
                                                    <Typography fontWeight='bold'>
                                                        €{item.attributes.price.toFixed(2)}
                                                    </Typography>
                                                </FlexBox>
                                            </Box>
                                        </FlexBox>
                                    </Box>
                                ))}
                            </Box>

                            {/* ACTIONS */}
                            <Box m='20px 0'>
                                {totalPrice <= 0 && <Typography variant='h6'>El carrito está vacío.</Typography>}
                                {totalPrice > 0 && <FlexBox m='20px 0'>
                                    <Typography variant='h6'>SUBTOTAL</Typography>
                                    <Typography variant='h6'>€{totalPrice.toFixed(2)}</Typography>
                                </FlexBox>}
                                {totalPrice > 0 && <FlexBox m='20px 0'>
                                    <Typography variant='h6'>Costo de envío</Typography>
                                    <Typography variant='h6'>€{shippingCost.toFixed(2)}</Typography>
                                </FlexBox>}
                                <FlexBox m='20px 0'>
                                    <Typography variant='h5'>TOTAL</Typography>
                                    <Typography variant='h5'>€{(totalPrice + shippingCost).toFixed(2)}</Typography>
                                </FlexBox>
                                {totalPrice > 0 && (<Button
                                    sx={{
                                        backgroundColor: shades.primary[400],
                                        color: 'white',
                                        borderRadius: 0,
                                        minWidth: '100%',
                                        padding: '20px 40px',
                                        m: '20px 0',
                                    }}
                                    onClick={async function() {
                                        // navigate('/checkout')
                                        const allAvailable = await checkProductsAvailability()
                                        if (allAvailable) {
                                            makePayment()
                                            dispatch(setIsCartOpen({}))
                                        } else {
                                            setAvailabilityError(true)
                                        }
                                    }}
                                >
                                    <Typography variant='h3'>
                                        CHECKOUT
                                    </Typography>
                                </Button>)}
                                {availabilityError && (<Alert severity="error">Availability error!</Alert>)}
                            </Box>
                        </Box>
                    </Box>
                </Slide>
            </Box>
        </Fade>
    )
}

export default CartMenu;