import { Box, Typography, IconButton, useMediaQuery } from '@mui/material'
import AOS from 'aos';
import { useEffect } from 'react';


const TartufinoLogo = () => {
    const isNonMobile = useMediaQuery('(min-width:600px)')
    useEffect(() => {
        AOS.init({ // Initialize AOS
            duration: 1000, // Adjust the animation duration as needed
            once: true // Set to true if you want the animation to occur only once
        });
    }, []);

    return (
        <Box
            m='60px auto 30px auto'
            // paddingBottom='70px'
            width='100%'
            data-aos="fade"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            data-aos-duration="1000"
        >
            <Typography
                fontFamily='Gluten'
                variant="h1"
                fontSize={isNonMobile ? '80px' : '50px'}
                align='center'
                marginBottom='0'
            >
                TARTUFINO
            </Typography>
            <Typography
                variant='h3'
                align='center'
                marginTop='-10px'
            >
                ECOSISTEMA GASTRONÓMICO
            </Typography>
        </Box>
    )
}

export default TartufinoLogo