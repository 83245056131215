import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tabs, Tab, Box, Typography, useMediaQuery } from '@mui/material'
import RecipePreview from './RecipePreview'
import { setItems } from '../../state'


const RecipesList = () => {
    const [value, setValue] = useState('all')
    // const items = useSelector((state) => state.cart.items)
    const [ items, setItems ] = useState([])
    const isNonMobile = useMediaQuery('(min-width:600px)')
    console.log("recipes", items)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    async function getItems() {
        const items = await fetch(
            `${process.env.REACT_APP_SERVER_BASE_URL}/api/recipes?populate=mainPicture`,
            { method: 'GET' }
        )
        const itemsJson = await items.json()
        setItems(itemsJson.data)
    }

    useEffect(() => {
        getItems()
    }, [])


    const tostadasItems = items.filter(
        (item) => item.attributes.category === 'tostadas'
    )

    const pastasItems = items.filter(
        (item) => item.attributes.category === 'pastas'
    )

    const segundosItems = items.filter(
        (item) => item.attributes.category === 'segundos'
    )

    const dulcesItems = items.filter(
        (item) => item.attributes.category === 'dulces'
    )

    // const pastaItems = items.filter(
    //     (item) => item.attributes.category === 'pasta'
    // )

    // const paraPicarItems = items.filter(
    //     (item) => item.attributes.category === 'paraPicar'
    // )

    // const dulcesItems = items.filter(
    //     (item) => item.attributes.category === 'dulces'
    // )

    // const snackItems = items.filter(
    //     (item) => item.attributes.category === 'snack'
    // )

    // const herramientasItems = items.filter(
    //     (item) => item.attributes.category === 'herramientas'
    // )

    // const quesosEmbutidosItems = items.filter(
    //     (item) => item.attributes.category === 'quesosYEmbutidos'
    // )

    return (
        <Box width='80%' m='80px auto'>
            <Box width='100%' margin='8px auto'>
                <Typography fontFamily='Gluten' variant='h2' textAlign='center' padding='5px 0px'>
                    RECETAS
                </Typography>
                <Tabs
                    textColor='primary'
                    indicatorColor='primary'
                    value={value}
                    onChange={handleChange}
                    centered
                    TabIndicatorProps={{ sx: { display: 'none'} }}
                    sx={{
                        m: '25px',
                        '& .MuiTabs-flexContainer': {
                            flexWrap: 'wrap'
                        }
                    }}
                >
                    <Tab label={<Typography fontSize='18px'>ALL</Typography>} value='all' />
                    <Tab label={<Typography fontSize='18px'>TOSTADAS</Typography>} value='tostadas' />
                    <Tab label={<Typography fontSize='18px'>PASTAS</Typography>} value='pastas' />
                    <Tab label={<Typography fontSize='18px'>SEGUNDOS</Typography>} value='segundos' />
                    <Tab label={<Typography fontSize='18px'>DULCES</Typography>} value='dulces' />
                    {/* <Tab label={<Typography fontSize='18px'>SNACKS</Typography>} value='snack' /> */}
                    {/* <Tab label={<Typography fontSize='18px'>DULCES</Typography>} value='dulces' />
                    <Tab label={<Typography fontSize='18px'>PASTA</Typography>} value='pasta' />
                    <Tab label={<Typography fontSize='18px'>PARA PICAR</Typography>} value='paraPicar' />
                    <Tab label={<Typography fontSize='18px'>QUESOS Y EMBUTIDOS</Typography>} value='quesosYEmbutidos' />
                    <Tab label={<Typography fontSize='18px'>HERRAMIENTAS</Typography>} value='herramientas' /> */}
                </Tabs>
                <Box
                    margin='0 auto'
                    display='grid'
                    gridTemplateColumns='repeat(auto-fill, 300px)'
                    justifyContent='space-around'
                    rowGap='20px'
                    columnGap='1.33%'
                >
                    {value === 'all' && items.map((item) => (
                        <RecipePreview item={item} key={`${item.title}-${item.id}`} />
                    ))}
                    {value === 'tostadas' && tostadasItems.map((item) => (
                        <RecipePreview item={item} key={`${item.title}-${item.id}`} />
                    ))}
                    {value === 'pastas' && pastasItems.map((item) => (
                        <RecipePreview item={item} key={`${item.title}-${item.id}`} />
                    ))}
                    {value === 'segundos' && segundosItems.map((item) => (
                        <RecipePreview item={item} key={`${item.title}-${item.id}`} />
                    ))}
                    {value === 'dulces' && dulcesItems.map((item) => (
                        <RecipePreview item={item} key={`${item.title}-${item.id}`} />
                    ))}
                    {/* {value === 'snack' && snackItems.map((item) => (
                        <RecipePreview item={item} key={`${item.title}-${item.id}`} />
                    ))} */}
                    {/* {value === 'pasta' && pastaItems.map((item) => (
                        <Item item={item} key={`${item.name}-${item.id}`} />
                    ))}
                    {value === 'paraPicar' && paraPicarItems.map((item) => (
                        <Item item={item} key={`${item.name}-${item.id}`} />
                    ))}
                    {value === 'dulces' && dulcesItems.map((item) => (
                        <Item item={item} key={`${item.name}-${item.id}`} />
                    ))}
                    {value === 'quesosYEmbutidos' && quesosEmbutidosItems.map((item) => (
                        <Item item={item} key={`${item.name}-${item.id}`} />
                    ))}
                    {value === 'herramientas' && herramientasItems.map((item) => (
                        <Item item={item} key={`${item.name}-${item.id}`} />
                    ))} */}
                </Box>
            </Box>
        </Box>
    )
}

export default RecipesList