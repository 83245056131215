import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { IconButton, Box, Typography, Button, Tabs, Tab, Alert, Link, useMediaQuery } from '@mui/material'
import Markdown from 'react-markdown'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { shades } from '../../theme'
import { addToCart } from '../../state'
import { useParams } from 'react-router-dom'
import Item from '../../components/Item'
import { Topic } from '@mui/icons-material'
import PreparationStepCard from './PreparationStepCard'



const RecipeDetails = () => {
    const dispatch = useDispatch()
    const { itemId } = useParams()
    const [value, setValue] = useState('description')
    const [item, setItem] = useState(null)
    const [prepImgs, setPrepImgs] = useState(null)
    const isNonMobile = useMediaQuery('(min-width:600px)')


    console.log('ITEM ID', itemId, prepImgs)
    
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    
    async function getItem() {
        const item = await fetch(
            `${process.env.REACT_APP_SERVER_BASE_URL}/api/recipes/${itemId}?populate=mainPicture&populate=preparationPictures`,
            { method: 'GET' }
        )
        const itemJson = await item.json()
        setItem(itemJson.data)
        // console.log("XD LOL", itemJson.data.attributes?.preparationPictures)
        setPrepImgs(itemJson.data.attributes?.preparationPictures?.data)
    }
        
    useEffect(() => {
        if (itemId) {
            getItem()
        }
    }, [itemId, item?.id])

    return (
        <>
            {item && <Box width='80%' m='100px auto'>
                <Box display='flex' flexWrap='wrap' columnGap='40px'>
                    {/* IMAGES */}
                    <Box flex='1 1 40%' m='40px 0'>
                        <img
                            alt={item?.title}
                            width='100%'
                            height='100%'
                            src={item?.attributes?.mainPicture?.data?.attributes?.url}
                            style={{ objectFit: 'contain' }}
                        />
                    </Box>

                    {/* ACTIONS */}
                    <Box marginTop='25px' flex='1 1 50%' mb='40px'>
                        {/* <Box display='flex' justifyContent='space-between'> */}
                        <Box><Link to='/recetas'>Recetas</Link></Box>
                            {/* <Box>Prev Next</Box> */}
                        {/* </Box> */}

                        <Box m='40px 0 25px 0'>
                            <Typography color='grey' sx={{ textTransform: 'uppercase' }}>{item?.attributes?.category}</Typography>
                            <Typography fontSize={isNonMobile ? '28px' : '24px'}>{item?.attributes?.title}</Typography>
                            <br/>
                            <Typography fontSize={isNonMobile ? '21px' : '18px'} sx={{ textTransform: 'uppercase' }}>Ingredientes</Typography>
                            {item?.attributes?.ingredients.map((el, idx) => {
                                return (
                                    Boolean(el.link) ?
                                    <Box display='flex' key={idx}>
                                        <Link fontWeight='bold' target="_blank" fontSize='16px' href={el.link}>{`${el.ingredient}`}</Link>
                                        <Typography fontSize='16px'>{`: ${el.quantity}`}</Typography>
                                    </Box> :
                                    <Box display='flex' key={idx}>
                                        <Typography fontWeight='bold' fontSize='16px'>{`${el.ingredient}`}</Typography>
                                        <Typography fontSize='16px'>{`: ${el.quantity}`}</Typography>
                                    </Box>
                                )
                            })}
                            {/* <Typography sx={{ mt: '20px' }} fontSize='16px'>
                                {item?.attributes?.ingredients}
                            </Typography> */}
                        </Box>

                        {/* INFORMATION */}
                        {/* <Box m='20px 0'>
                            <Tabs value={value} onChange={handleChange}>
                                <Tab label='DESCRIPTION' value='description' />
                            </Tabs>
                        </Box>
                        <Box display='flex' flexWrap='wrap' gap='15px'>
                            {value === 'description' && (
                                <Typography sx={{ whiteSpace: 'pre-line'}} fontSize='16px'>{"item?.attributes?.preparation"}</Typography>
                            )}
                        </Box> */}
                    </Box>
                </Box>
                <Box width='80%' m='20px auto'>
                    <Typography align='center' fontSize={isNonMobile ? '23px' : '18px'} sx={{ textTransform: 'uppercase' }}>Preparación</Typography>
                    {item?.attributes?.preparation.map((el, idx) => {
                        // return(
                        //     <Box key={idx}>
                        //         <Typography>{el.subtitle}</Typography>
                        //         <Markdown>{el.content}</Markdown>
                        //     </Box>
                        // )
                        return (
                            <PreparationStepCard
                                imageUrl={prepImgs ? prepImgs[idx].attributes.url : null}
                                description={el.content}
                                title={el.subtitle}
                                elIdx={idx}
                                key={idx}
                            />
                        )
                    })}
                </Box>
            </Box>}
        </>
    )
}

export default RecipeDetails