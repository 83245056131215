import { Instagram, Mail, WhatsApp } from '@mui/icons-material'
import { Box, IconButton, Typography, useMediaQuery } from '@mui/material'

const Contacts = () => {
    const isNonMobile = useMediaQuery('(min-width:600px)')

    const openWhatsappLink = () => {
        // Construct the Google Drive link
        // const driveLink = `https://drive.google.com/file/d/${fileId}/view`;
        const driveLink = `https://wa.me/+34691726328`;
    
        // Open the link in a new tab
        window.open(driveLink, '_blank');
    };

    const openEmailLink = () => {
        // Construct the Google Drive link
        // const driveLink = `https://drive.google.com/file/d/${fileId}/view`;
        const driveLink = `mailto:hola@tartufinoes.com`;
    
        // Open the link in a new tab
        window.open(driveLink, '_blank');
    };

    const openInstagramLink = () => {
        // Construct the Google Drive link
        // const driveLink = `https://drive.google.com/file/d/${fileId}/view`;
        const driveLink = `https://www.instagram.com/tartufinoes/`;
    
        // Open the link in a new tab
        window.open(driveLink, '_blank');
    };

    return (
        <Box width='80%' m='80px auto'>
            <Box
                m='10px 10px'
                p='10px 0px'
            >
                <Typography
                    variant='h2'
                    align='center'
                    fontFamily='Gluten'
                >
                    CONTACTOS
                </Typography>
            </Box>
            <Box
                m='10px 10px'
                p='10px 0px'
                align='center'
            >
                <Typography
                    variant='h2'
                    align='center'
                    marginTop='10px'
                >
                    Pedidos
                </Typography>

                <Box
                    align='center'
                >
                    <Box
                        alignItems='center'
                        m={isNonMobile ? '20px' : '20px 0px'}
                        // width={isNonMobile ? '30%' : '100%'}
                        maxWidth="min-content"
                    >
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent='left'
                        >
                            <IconButton onClick={() => openWhatsappLink()}>
                                <WhatsApp
                                    fontSize='large'
                                    // sx={{ '&:hover': { cursor: "pointer" } }}
                                />
                            </IconButton>
                            <Typography
                                onClick={() => openWhatsappLink()}
                                fontSize={'30px'}
                                fontFamily='Gluten'
                                p='4px 8px 0px 8px'
                                sx={{ '&:hover': { cursor: "pointer" } }}
                                alignSelf='center'
                            >
                                691726328
                            </Typography>
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent='left'
                        >
                            <IconButton onClick={() => openEmailLink()}>
                                <Mail
                                    fontSize='large'
                                    // sx={{ '&:hover': { cursor: "pointer" } }}
                                />
                            </IconButton>
                            <Typography
                                onClick={() => openEmailLink()}
                                fontSize={'20px'}
                                p='0px 8px'
                                sx={{ '&:hover': { cursor: "pointer" } }}
                                alignSelf='center'
                            >
                                hola@tartufinoes.com
                            </Typography>
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent='left'
                        >
                            <IconButton onClick={() => openInstagramLink()}>
                                <Instagram
                                    fontSize='large'
                                    // sx={{ '&:hover': { cursor: "pointer" } }}
                                />
                            </IconButton>
                            <Typography
                                onClick={() => openInstagramLink()}
                                fontSize={'20px'}
                                p='0px 8px'
                                sx={{ '&:hover': { cursor: "pointer" } }}
                                alignSelf='center'
                            >
                                @tartufinoes
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Contacts