import { useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation
} from 'react-router-dom';
import Home from "./scenes/home/Home";
import ItemDetails from "./scenes/itemDetails/ItemDetails";
import Checkout from "./scenes/checkout/Checkout";
import Confirmation from "./scenes/checkout/Confirmation";
import Navbar from "./scenes/global/Navbar";
import CartMenu from "./scenes/global/CartMenu";
import Sidebar from "./scenes/global/Sidebar";
import Footer from "./scenes/global/Footer";
import Shop from "./scenes/shop/Shop";
import Tartufino from "./scenes/tartufino/Tartufino";
import AboutUs from "./scenes/aboutus/AboutUs";
import Careers from "./scenes/careers/Careers";
import Contacts from "./scenes/contacts/Contacts";
import GeneralPolicy from "./scenes/policies/GeneralPolicy";
import RecipesList from "./scenes/recipes/RecipesList";
import RecipeDetails from "./scenes/recipes/RecipeDetails";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname])

  return null;
}


function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="item/:itemId" element={<ItemDetails />} />
          <Route path="receta/:itemId" element={<RecipeDetails />} />
          <Route path="checkout" element={<Checkout />} />
          <Route path="checkout/success" element={<Confirmation />} />
          <Route path="shop" element={<Shop />} />
          <Route path="recetas" element={<RecipesList />} />
          <Route path="shop/trufa-fresca" element={<Shop activeTab={'trufaFresca'} />} />
          <Route path="tartufino" element={<Tartufino />} />
          <Route path="quiensomos" element={<AboutUs />} />
          <Route path="trabajaconnosotros" element={<Careers />} />
          <Route path="contactos" element={<Contacts />} />
          <Route path="privacy-policy" element={<GeneralPolicy pageId={4} />} />
          <Route path="condiciones-de-venta" element={<GeneralPolicy pageId={1} />} />
          <Route path="metodos-de-pago" element={<GeneralPolicy pageId={2} />} />
          <Route path="politica-de-devoluciones" element={<GeneralPolicy pageId={3} />} />
        </Routes>
        <CartMenu />
        <Sidebar />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
