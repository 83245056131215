import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { IconButton, Box, Typography, useTheme, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const RecipePreview = ({ item, width }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [isHovered, setIsHovered] = useState(false)
    const {
        palette: { neutral },
    } = useTheme()
    const { category, preparation, title, mainPicture, ingredients } = item.attributes
    console.log("IMAGE", mainPicture)
    
    const url = mainPicture.data.attributes.url

    
    return (
        <Box width={width}>
            <Box
                position='relative'
                onMouseOver={() => setIsHovered(true)}
                onMouseOut={() => setIsHovered(false)}
            >
                <img
                    alt={item.name}
                    width='300px'
                    height='300px'
                    src={url}
                    onClick={() => navigate(`/receta/${item.id}`)}
                    style={{ cursor: 'pointer', objectFit: 'cover' }}
                />
                <Box
                    display={isHovered ? 'block' : 'none'}
                    position='absolute'
                    bottom='10%'
                    left='0'
                    width='100%'
                    padding='0 5%'
                    // backgroundColor='rgba(1, 1, 1, 0.4)'
                >
                    {/* {(availableQuantity == 0 || quantityMultiplier > availableQuantity) && 
                        <Box
                            display='flex'
                            justifyContent='center'
                            flexDirection='column'
                            justifySelf='top'
                            // backgroundColor='white'
                            sx={{height: '200px'}}
                        >
                            <Typography align='center' m='0 20px' fontSize='18px'>Vuelve mañana!</Typography>
                        </Box>
                    } */}
                    {/* <Box display='flex' justifyContent='space-between'> */}
                        {/* AMOUNT */}
                        {/* <Box
                            display='flex'
                            alignItems='center'
                            backgroundColor={shades.neutral[100]}
                            borderRadius='3px'
                        >
                            <IconButton
                                onClick={() => setCount(Math.max(count - 1, 1))}
                                disabled={availableQuantity == 0}
                            >
                                <RemoveIcon />
                            </IconButton>
                            <Typography color={shades.primary[300]}>{count}</Typography>
                            <IconButton
                                onClick={() => setCount(count + 1)}
                                disabled={(count+1) * quantityMultiplier > availableQuantity}
                            >
                                <AddIcon />
                            </IconButton>
                        </Box> */}

                        {/* BUTTON */}
                        {/* <Button
                            onClick={() => {
                                dispatch(addToCart({ item: { ...item, count } }))
                            }}
                            sx={{
                                backgroundColor: shades.primary[300],
                                color: 'white',
                                "&.Mui-disabled": {
                                    background: "#DC143C",
                                    color: "white"
                                }
                            }}
                            disabled={availableQuantity == 0 || quantityMultiplier > availableQuantity}
                        >
                            {availableQuantity == 0 || quantityMultiplier > availableQuantity ? "SOLD OUT" : "ADD TO CART"}
                        </Button> */}
                    {/* </Box> */}
                </Box>
            </Box>

            <Box mt='3px'>
                <Typography variant='subtitle2' color={neutral.dark}>
                    {category
                        .replace(/([A-Z])/g, ' $1')
                        .replace(/^./, (str) => str.toUpperCase())}
                </Typography>
                <Typography
                    onClick={() => navigate(`/receta/${item.id}`)}
                    fontSize='20px'
                    sx={{cursor: 'pointer'}}
                >
                    {title}
                </Typography>
                {/* <Typography fontSize='20px' fontWeight='bold'>€{price.toFixed(2)}</Typography> */}
            </Box>
        </Box>
    )
}

export default RecipePreview