import { Box, Typography, useMediaQuery } from '@mui/material'
import SofyAndFede from '../../assets/IMG_7706.jpg'

const AboutUs = () => {
    const isNonMobile = useMediaQuery('(min-width:600px)')


    return (
        <Box width='80%' m='auto' marginTop='-40px'>
            <Box
                m='10px 10px'
                p='10px 0px'
            >
                <Typography
                    variant='h2'
                    align='center'
                    fontFamily='Gluten'
                >
                    QUIEN SOMOS
                </Typography>
            </Box>
            <Box
                m='10px 20px'
                p='10px 0px'
            >
                <Typography
                    variant='h3'
                    align='justify'
                >
                    <span style={{ fontFamily: 'Gluten' }}>TARTUFINO</span> es una idea surgida del amor de Sofia Marchesini y Federico Castagna por la cocina sostenible y los productos de alta calidad. Sofia, especializada en productos italianos y en el comercio internacional, Federico, especializado en el mercado alicantino y en el mundo de la hostelería.
                </Typography>
                <Box
                    display="grid"
                    gridTemplateColumns={{ xs: '1fr', md: '1fr 1fr' }}
                    alignItems='center'
                    gap='0px'
                    mt='10px'
                >
                    <Box
                        width='100%'
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Box
                            component="img"
                            sx={{
                                maxWidth: { xs: '90%', md: '85%' },
                                maxHeight: '250px',
                                m: '20px'
                            }}
                            alt="mission"
                            src={SofyAndFede}
                        />
                    </Box>
                    <Typography
                        variant='h3'
                        // align={ isNonMobile ? 'left' : 'center' }
                        align='justify'
                        justify='center'
                    >
                        Comenzaron importando trufa fresca y productos trufados de una pequeña empresa toscana, al darse cuenta de que era difícil encontrar productos trufados de buena calidad en su área local.<br/><br/>
                        Iniciaron una pequeña distribución de estos productos y rápidamente notaron la necesidad de suportar a los clientes, ya que muchas veces no sabían valorar su calidad. Fue entonces cuando surgió la idea de un sistema educativo, tanto a nivel profesional como privado.
                    </Typography>
                </Box>
                <Typography
                    fontSize='25px'
                    align='center'
                    lineHeight='25px'
                    marginTop={ isNonMobile ? '45px' : '40px' }
                >
                    «La evolución lo es todo. El melocotón alguna vez fue una almendra amarga; La coliflor es sólo un repollo que ha ido a la universidad.»<br/>Mark Twain
                </Typography>
            </Box>
        </Box>
    )
}

export default AboutUs