import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const GeneralPolicy = ({pageId}) => {
    const [pageContent, setPageContent] = useState(null)

    async function getPage() {
        const item = await fetch(
            `${process.env.REACT_APP_SERVER_BASE_URL}/api/markdown-pages/${pageId}`,
            { method: 'GET' }
        )
        const itemJson = await item.json()
        setPageContent(itemJson.data)
    }

    useEffect(() => {
        getPage()
    }, [pageId])

    console.log(pageContent)

    return (
        <Box width='80%' m='80px auto'>
            <Typography
                variant='h1'
                align='center'
            >
                {pageContent?.attributes?.title}
            </Typography>
            <Markdown className='markdown-component' remarkPlugins={[remarkGfm]}>
                {pageContent?.attributes?.content}
            </Markdown>
        </Box>
    )
}

export default GeneralPolicy