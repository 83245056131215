import React, { useEffect, useState } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Markdown from 'react-markdown'

const PreparationStepCard = ({imageUrl, title, description, elIdx}) => {
    const [ isHovered, setIsHovered ] = useState(false)
    const isNonMobile = useMediaQuery("(min-width:600px)")

    console.log('imgurl', imageUrl)

    useEffect(() => {
        AOS.init({ // Initialize AOS
            duration: 1000, // Adjust the animation duration as needed
            once: true // Set to true if you want the animation to occur only once
        });
    }, []);

    return (
        <Box
            m='10px auto'
            display='flex'
            alignItems='center'
            justifyContent='center'
            id={`parent-box-${elIdx}`}
            flexDirection={isNonMobile ? 'row' : 'column'}
        >
            <img
                src={imageUrl}
                width={isNonMobile ? '30%' : '75%'}
                style={{
                    maxHeight: '238px',
                    objectFit: 'cover',
                    // cursor: isHovered ? 'pointer' : 'pointer',
                    padding: '20px'
                }}
                data-aos={isNonMobile ? 'fade-right' : 'fade'}
                data-aos-easing="ease-in-sine"
                data-aos-duration="1000"
                data-aos-anchor-placement="bottom-bottom"
                data-aos-anchor={`#parent-box-${elIdx}`}
                onMouseOver={() => setIsHovered(true)}
                onMouseOut={() => setIsHovered(false)}
            ></img>
            <Box
                width={isNonMobile ? '40%' : '85%'}
                data-aos={isNonMobile ? 'fade-left' : 'fade-up'}
                data-aos-easing="ease-in-sine"
                data-aos-duration="1000"
                data-aos-anchor-placement="bottom-bottom"
                data-aos-anchor={`#parent-box-${elIdx}`}
                m='0 30px'
            >
                <Typography
                    color='grey'
                    sx={{ textTransform: 'uppercase' }}
                    align={isNonMobile ? 'left' : 'center'}
                >
                    {title}
                </Typography>
                <Markdown className='recipe-prep-markdown-component'>
                    {description}
                </Markdown>
            </Box>
        </Box>
    )
}

export default PreparationStepCard;
