import { Box, Alert, AlertTitle } from "@mui/material"
import { emptyCart } from "../../state";
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
    'pk_test_51OAywPFlPlWUKPCFgKjx7UcNtszkk5xsiBAv2UMt1eIhYllH6S1aYQtKhzvKFV6agFIUzmKLY6NIebBKU75VOjC200AZlZnYL8'
)

const Confirmation = () => {
    // localStorage.setItem('cartItems', []);
    const dispatch = useDispatch()
    const cart = useSelector((state) => state.cart.cart)
    
    const queryParameters = new URLSearchParams(window.location.search)
    const sessionId = queryParameters.get("session_id")
    
    async function updateInventory( sessionId ) {
        const requestBody = {
            stripeSessionId: sessionId
        }
        const response = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/api/updateInventoryQuantity`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify(requestBody)
        })
        const res = await response.json()
        console.log('Inventory updated:', res.updated)
        return res
    }
            
    useEffect(()=>{
        updateInventory(sessionId)
    }, [])
            
    useEffect(()=>{
        dispatch(emptyCart())
    }, [])

    return (
        <Box m='90px auto' width='80%' height='50vh'>
            <Alert severity='success'>
                <AlertTitle>Éxito</AlertTitle>
                Has realizado con éxito una orden. - {' '}
                <strong>¡Felicidades por realizar tu compra!</strong>
            </Alert>
        </Box>
    )
}

export default Confirmation