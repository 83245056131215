import { Box, Typography } from '@mui/material'
import missionImg from '../../assets/image_123650291.jpg'
import AboutUs from '../aboutus/AboutUs'

// import
const importAll = (r) =>
    r.keys().reduce((acc, item) => {
        acc[item.replace('./', '')] = r(item)
        return acc
    }, {})

export const heroTextureImports = importAll(
    require.context('../../assets', false, /\.(png|jpe?g|svg)$/)
)

const Tartufino = () => {
    return (
        <>
            <Box width='80%' m='80px auto'>
                <Box
                    m='20px 10px'
                    p='20px 0px'
                >
                    <Typography
                        fontFamily='Gluten'
                        variant='h1'
                        align='center'
                    >
                        TARTUFINO
                    </Typography>
                    <Typography
                        variant='h3'
                        align='center'
                        marginTop='-8px'
                    >
                        ECOSISTEMA GASTRONÓMICO
                    </Typography>
                </Box>
                <Box
                    m='20px 20px'
                    p='20px 0px'
                >
                    <Typography
                        variant='h3'
                        align='justify'
                    >
                        Nuestra misión es mejorar el sector gastronómico, aumentando su calidad y contribuyendo a la construcción de un sistema alimentario sostenible: alimentos saludables con un impacto positivo en el medio ambiente.<br/>
                    </Typography>
                    <Box
                        width='100%'
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Box
                            component="img"
                            sx={{
                                maxWidth: { xs: '90%', md: '30%' },
                                m: '10px'
                            }}
                            alt="mission"
                            src={missionImg}
                        />
                    </Box>
                    <Typography
                        variant='h3'
                        align='justify'
                    >
                        <span style={{ fontFamily: 'Gluten' }}>TARTUFINO</span> busca ser un ecosistema que promueva y aplique estos estándares, invirtiendo desde la producción de materias primas hasta la educación, llegando al consumidor final, creando así una economía circular y un modelo de negocio escalable.
                    </Typography>
                </Box>
            </Box>
            <AboutUs />
        </>
    )
}

export default Tartufino