import React, { useEffect, useState } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css';
import truffleImg from '../../assets/truffle.jpeg';

const SectionCard = ({image, title, description, elIdx, navigation}) => {
    const words = description.split(' ');
    const [ isHovered, setIsHovered ] = useState(false)
    const navigate = useNavigate()
    const isNonMobile = useMediaQuery("(min-width:600px)")
    const mulFactor = isNonMobile ? 0 : 0

    useEffect(() => {
        AOS.init({ // Initialize AOS
            duration: 1000, // Adjust the animation duration as needed
            once: true // Set to true if you want the animation to occur only once
        });
    }, []);

    return (
        <Box
            m='10px auto'
            display='flex'
            alignItems='center'
            justifyContent='center'
            id={`parent-box-${elIdx}`}
            flexDirection={isNonMobile ? 'row' : 'column'}
        >
            <img
                src={image}
                width={isNonMobile ? '30%' : '75%'}
                style={{
                    maxHeight: '238px',
                    objectFit: 'cover',
                    cursor: isHovered ? 'pointer' : 'pointer'
                }}
                data-aos={isNonMobile ? 'fade-right' : 'fade'}
                data-aos-easing="ease-in-sine"
                data-aos-duration="1000"
                data-aos-anchor-placement="top-center"
                data-aos-anchor={`#parent-box-${elIdx}`}
                data-aos-offset={mulFactor*elIdx}
                onClick={ () => navigate(navigation)}
                onMouseOver={() => setIsHovered(true)}
                onMouseOut={() => setIsHovered(false)}
            ></img>
            <Box
                width={isNonMobile ? '30%' : '75%'}
                data-aos={isNonMobile ? 'fade-left' : 'fade-up'}
                data-aos-easing="ease-in-sine"
                data-aos-duration="1000"
                data-aos-anchor-placement="top-center"
                data-aos-anchor={`#parent-box-${elIdx}`}
                data-aos-offset={mulFactor*elIdx}
            >
                <Typography
                    variant='h2'
                    fontWeight='bold'
                    onClick={ () => navigate(navigation)}
                    onMouseOver={() => setIsHovered(true)}
                    onMouseOut={() => setIsHovered(false)}
                    align={isNonMobile ? 'left' : 'center'}
                    sx={{
                        '&:hover': { cursor: "pointer" },
                        textDecoration: isHovered ? 'underline' : '',
                        textTransform: 'uppercase'
                    }}
                >
                    {title}
                </Typography>
                <Typography fontSize='20px' align={isNonMobile ? 'left' : 'justify'}>
                    {words.map((word, index) => {
                        // Check if the word is "MERCADILLO" and apply custom style
                        if (word === 'MERCADILLO,') {
                            return (
                                <span key={index}>
                                    <span
                                        style={{
                                            fontFamily: 'Gluten',
                                            cursor: isHovered ? 'pointer' : 'pointer'
                                        }}
                                        onClick={ () => navigate(navigation)}
                                        onMouseOver={() => setIsHovered(true)}
                                        onMouseOut={() => setIsHovered(false)}
                                    >
                                        MERCADILLO
                                    </span>
                                    ,&nbsp;
                                </span>
                            );
                        } else {
                            return <span key={index}>{word} </span>;
                        }
                    })}
                </Typography>
            </Box>
        </Box>
    )
}

export default SectionCard;
