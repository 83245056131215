import {
    MenuOutlined,
    ShoppingBagOutlined,
} from '@mui/icons-material'
import { Badge, Box, IconButton, Typography, useMediaQuery } from "@mui/material"
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { setIsCartOpen, setIsSidebarOpen } from '../../state'
import { useEffect, useState } from 'react'

const Navbar = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [ transparentNav, setTransparentNav ] = useState(true)
    const location = useLocation()
    console.log('aaaaaaaa', location)
    const cart = useSelector((state) => state.cart.cart)
    const isNonMobile = useMediaQuery('(min-width:600px)')

    useEffect(() => {
        const handleScroll = () => {
            const show = window.scrollY > window.innerHeight - 50
            console.log('lets see', location.pathname != '/', location.pathname)
            if (location.pathname != '/' || show) {
                setTransparentNav(false)
            } else {
                setTransparentNav(true)
            }
        }
        handleScroll()
        document.addEventListener('scroll', handleScroll)
        return () => {
            document.removeEventListener('scroll', handleScroll)
        }
    }, [location])

    return (
        <>
            <Box
                display="flex"
                alignItems="center"
                width="100%"
                height="30px"
                backgroundColor='#16100f'
                color="white"
                position="fixed"
                top="0"
                left="0"
                zIndex="1"
            >
                <Typography m='auto'>
                    ENVÍO GRATIS A PARTIR DE €50
                </Typography>
            </Box>
            <Box
                display="flex"
                alignItems="center"
                width="100%"
                height="60px"
                backgroundColor={`rgba(255, 255, 255, ${transparentNav ? '0' : '0.95'})`}
                color="black"
                position="fixed"
                top="30px"
                left="0"
                zIndex="1"
            >
                <Box
                    width="85%"
                    margin="auto"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Box
                        onClick={() => navigate("/")}
                        sx={{ '&:hover': { cursor: "pointer" } }}
                        color='black'
                        justifyContent='center'
                    >
                        <Typography
                            fontFamily='Gluten'
                            variant={isNonMobile ? 'h2' : 'h3'}
                            paddingTop={isNonMobile ? '15px' : '4px'}
                            color={transparentNav ? 'white' : 'black'}
                        >
                            TARTUFINO
                        </Typography>
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        columnGap="20px"
                        zIndex="2"
                    >
                        <Badge
                            badgeContent={cart.length}
                            color='secondary'
                            invisible={cart.length === 0}
                            sx={{
                                "& .MuiBadge-badge": {
                                    right: 10,
                                    top: 10,
                                    padding: "0 4px",
                                    height: "15px",
                                    minWidth: "15px",
                                    fontSize: '10px',
                                    fontFamily: 'Arial'
                                }
                            }}
                        >
                            <IconButton
                                onClick={() => dispatch(setIsCartOpen({}))}
                                sx={{ color: transparentNav ? 'white' : 'black' }}>
                                <ShoppingBagOutlined fontSize={isNonMobile ? 'large' : 'medium'}/>
                            </IconButton>
                        </Badge>
                        <IconButton
                            sx={{ color: transparentNav ? 'white' : 'black' }}
                            onClick={() => dispatch(setIsSidebarOpen({}))}
                        >
                            <MenuOutlined fontSize={isNonMobile ? 'large' : 'medium'}/>
                        </IconButton>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default Navbar