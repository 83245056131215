import { Box, IconButton, Typography, Fade, Slide } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close'
import styled from '@emotion/styled'
import { setIsSidebarOpen } from '../../state'
import { useNavigate } from 'react-router-dom'

const FlexBox = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const Sidebar = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const isSidebarOpen = useSelector((state) => state.cart.isSidebarOpen)

    return (
        <Fade in={isSidebarOpen} mountOnEnter unmountOnExit>
            <Box // overlay
                // display={isSidebarOpen ? 'block' : 'none'}
                backgroundColor='rgba(0, 0, 0, 0.4)'
                position='fixed'
                zIndex={10}
                width='100%'
                height='100%'
                left='0'
                top='0'
                overflow='auto'
                onClick={() => dispatch(setIsSidebarOpen({}))}
            >
                <Slide in={isSidebarOpen} direction="left" mountOnEnter unmountOnExit>
                    {/* MODAL */}
                    <Box
                        position='fixed'
                        width='max(300px, 30%)'
                        height='100%'
                        right='0'
                        bottom='0'
                        backgroundColor='white'
                        onClick={(e) => e.stopPropagation()}
                    >
                        <Box padding='30px' overflow='auto' height='100%'>
                            {/* HEADER */}
                            <FlexBox mb='15px'>
                                <Typography fontFamily='Gluten' variant='h2'>MENU</Typography>
                                <IconButton onClick={() => dispatch(setIsSidebarOpen({}))}>
                                    <CloseIcon />
                                </IconButton>
                            </FlexBox>

                            {/* SECTIONS */}
                            <Box>
                                <Typography variant='h5'
                                    padding='4px 0px'
                                    onClick={() => {
                                        navigate('/')
                                        dispatch(setIsSidebarOpen({}))
                                    }}
                                    sx={{ '&:hover': { cursor: "pointer" } }}
                                >
                                    Home
                                </Typography>
                                {/* <Typography variant='h5'
                                    padding='4px 0px'
                                    onClick={() => {
                                        navigate('/quiensomos')
                                        dispatch(setIsSidebarOpen({}))
                                    }}
                                    sx={{ '&:hover': { cursor: "pointer" } }}
                                    >
                                    Quien somos
                                </Typography> */}
                                {/* <Typography variant='h5'
                                    padding='4px 0px'
                                    onClick={() => {
                                        navigate('/shop')
                                        dispatch(setIsSidebarOpen({}))
                                    }}
                                    sx={{ '&:hover': { cursor: "pointer" } }}
                                    >
                                    Noticias
                                </Typography> */}
                                <Typography variant='h5'
                                    padding='4px 0px'
                                    onClick={() => {
                                        navigate('/shop')
                                        dispatch(setIsSidebarOpen({}))
                                    }}
                                    sx={{ '&:hover': { cursor: "pointer" } }}
                                    fontFamily='Gluten'
                                >
                                    MERCADILLO
                                </Typography>
                                <Typography variant='h5'
                                    padding='4px 0px'
                                    onClick={() => {
                                        navigate('/tartufino')
                                        dispatch(setIsSidebarOpen({}))
                                    }}
                                    sx={{ '&:hover': { cursor: "pointer" } }}
                                >
                                    Quien somos
                                </Typography>
                                <Typography variant='h5'
                                    padding='4px 0px'
                                    onClick={() => {
                                        navigate('/recetas')
                                        dispatch(setIsSidebarOpen({}))
                                    }}
                                    sx={{ '&:hover': { cursor: "pointer" } }}
                                >
                                    Recetas
                                </Typography>
                                <Typography variant='h5'
                                    padding='4px 0px'
                                    onClick={() => {
                                        navigate('/trabajaconnosotros')
                                        dispatch(setIsSidebarOpen({}))
                                    }}
                                    sx={{ '&:hover': { cursor: "pointer" } }}
                                >
                                    Trabaja con nosotros
                                </Typography>
                                <Typography variant='h5'
                                    padding='4px 0px'
                                    onClick={() => {
                                        navigate('/contactos')
                                        dispatch(setIsSidebarOpen({}))
                                    }}
                                    sx={{ '&:hover': { cursor: "pointer" } }}
                                >
                                    Contactos
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Slide>
            </Box>
        </Fade>
    )
}

export default Sidebar;