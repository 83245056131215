import { Box, Typography, IconButton, TextField, useMediaQuery, Button, FormControlLabel, Checkbox, Alert } from "@mui/material"
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined'
import { useState } from "react"
import { shades } from '../../theme'
import emailjs from '@emailjs/browser'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'



const Subscribe = ({mailFormRef}) => {
    const [userConsentPrivacy, setUserConsentPrivacy] = useState(false)

    const isNonMobile = useMediaQuery('(min-width:600px)')

    const [emailSent, setEmailSent] = useState(false)

    const openGoogleDriveLink = () => {
        // Construct the Google Drive link
        // const driveLink = `https://drive.google.com/file/d/${fileId}/view`;
        const driveLink = `https://drive.google.com/file/d/1pQAgv92U1ebvyBgH8DdSQ3Pv9eibGfsu/view?usp=drivesdk`;
    
        // Open the link in a new tab
        window.open(driveLink, '_blank');
    };

    const sendEmail = (e) => {
        e.preventDefault();

        console.log(e)
    
        emailjs.sendForm('service_6zegbuv', 'template_u6ihej9', e.target, 'joV093hf9bV6iPuYl')
            .then((result) => {
                console.log(result.text);
                setEmailSent(true)
            }, (error) => {
                console.log(error.text);
            });
      };

    return (
        <Box width='80%' margin='40px auto' textAlign='center'>
            <Typography variant='h2'>¿TIENES UN RESTAURANTE?</Typography>
            <Box
                display="grid"
                gridTemplateColumns={{ xs: '1fr', md: '1fr 1fr' }}
                gap='0px'
            >
                <Box
                    display="flex"
                    order={{ xs: 1, md: 1 }}
                    alignContent='center'
                    justifyContent='center'
                    p='2px 4px'
                    m={isNonMobile ? '15px auto' : '15px 0px 0px 0px'}
                    flexDirection='column'
                >
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent='center'
                    >
                        <IconButton onClick={() => openGoogleDriveLink()}>
                            <FileDownloadIcon
                                fontSize='large'
                                // sx={{ '&:hover': { cursor: "pointer" } }}
                            />
                        </IconButton>
                        <Typography
                            onClick={() => openGoogleDriveLink()}
                            variant='h3'
                            sx={{ '&:hover': { cursor: "pointer" } }}
                            alignSelf='center'
                        >
                            Descargar Catàlogo
                        </Typography>
                    </Box>
                </Box>
                {/* <Divider sx={{ height: 28, m: 0.5 }} orientation='vertical' /> */}
                <Box
                    p='2px 4px'
                    m='15px auto'
                    display='flex'
                    alignContent='center'
                    justifyContent='center'
                    width='90%'
                    // backgroundColor='#F2F2F2'
                    order={{ xs: 2, md: 2 }}
                >
                    <Box
                        display="grid"
                        gap='15px'
                        width={isNonMobile ? '100%' : '100%'}
                        alignItems='center'
                        alignSelf='center'
                    >
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent='center'
                        >
                            <IconButton>
                                <MarkEmailReadOutlinedIcon fontSize='large' />
                            </IconButton>
                            <Typography variant='h3' alignSelf='center'>Envìanos un mensaje</Typography>
                        </Box>
                        <form onSubmit={sendEmail}>
                            <Box
                                display="grid"
                                gap='15px'
                                width='100%'
                                alignItems='center'
                                alignSelf='center'
                            >
                                <TextField
                                    // sx={{ ml: 1, flex: 1 }}
                                    placeholder='Nombre'
                                    name='user_name'
                                />
                                <TextField
                                    // sx={{ ml: 1, flex: 1 }}
                                    placeholder='E-mail'
                                    type='email'
                                    name='user_email'
                                />
                                <TextField
                                    // sx={{ ml: 1, flex: 1 }}
                                    multiline
                                    rows={5}
                                    placeholder='Preguntas y pedidos'
                                    name='message'
                                />
                                <FormControlLabel
                                    label={<Typography>Confirmo que acepto la <Link to='/privacy-policy' target="_blank">política de privacidad</Link></Typography>}
                                    control={
                                        <Checkbox
                                            checked={userConsentPrivacy}
                                            onChange={() =>
                                                setUserConsentPrivacy(
                                                    !userConsentPrivacy
                                                )
                                            }
                                        />
                                    }
                                />
                                {emailSent && <Alert severity="success">Email enviada correctamente.</Alert>}
                                <Button
                                    sx={{
                                        backgroundColor: shades.primary[400],
                                        color: 'white',
                                        borderRadius: 0,
                                        minWidth: '100%',
                                        padding: '20px 40px',
                                    }}
                                    onClick={() => {
                                    }}
                                    disabled={!userConsentPrivacy}
                                    type="submit"
                                >
                                    <Typography variant='h3'>
                                        ENVIAR
                                    </Typography>
                                </Button>
                            </Box>
                        </form>
                    </Box>
                    {/* <Divider sx={{ height: 28, m: 0.5 }} orientation='vertical' />
                    <Typography sx={{ p: '10px', ':hover': {cursor: 'pointer'}}}>Subscribe</Typography> */}
                </Box>
            </Box>
        </Box>
    )
}


export default Subscribe