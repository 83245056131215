import { useRef } from 'react'
import MainCarousel from './MainCarousel'
import Subscribe from './Subscribe'
import SectionCard from './SectionCard'
import TartufinoLogo from './TartufinoLogo'
import { Box } from '@mui/material'

import truffleOilImg from '../../assets/truffle_oil.png'
import truffleImg from '../../assets/truffle.jpeg';



const Home = () => {
    const mailFormRef = useRef(null)

    const cardsData = [
        {
            image: truffleImg,
            title: 'Trufa',
            description: 'Seleccionamos las mejores trufas italianas y españolas según la temporada y según la necesidad de cada cliente.',
            navigation: '/shop/trufa-fresca'
        },
        {
            image: truffleOilImg,
            title: 'Productos Trufados',
            description: 'Trabajamos con un amplia gama de productos trufados de alta calidad, con aromas naturales y certificados, mantenendo un precio muy accesible.',
            navigation: '/shop'
        },
        {
            image: truffleImg,
            title: 'Showroom',
            description: 'En nuestra tienda MERCADILLO, situada en el centro de Alicante, podrás encontrar el producto perfecto para tus necesidades y eventos.',
            navigation: '/shop/trufa-fresca'
        },
        {
            image: truffleOilImg,
            title: 'Profesionales',
            description: 'Ayudamos a los profesionales en la venta, desarrollo y aplicación a la cocina de trufa y productos trufados.',
            navigation: '/trabajaconnosotros'
        }
    ]

    return (
        <div className='home'>
            <MainCarousel mailFormRef={mailFormRef} />
            <TartufinoLogo />
            <Box
                width='100%'
                alignItems='center'
            >
                {cardsData.map((el, idx) => <SectionCard
                                                key={idx}
                                                elIdx={idx}
                                                image={el.image}
                                                title={el.title}
                                                description={el.description}
                                                navigation={el.navigation}
                                            />
                )}
                {/* <SectionCard />
                <SectionCard /> */}
            </Box>
            <Subscribe mailFormRef={mailFormRef} />
        </div>
    )
}

export default Home