import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tabs, Tab, Box, Typography, useMediaQuery } from '@mui/material'
import Item from '../../components/Item'
import { setItems } from '../../state'



const ShoppingList = ({activeTab}) => {
    const dispatch = useDispatch()
    const [value, setValue] = useState(activeTab ? activeTab : 'all')
    const items = useSelector((state) => state.cart.items)
    const isNonMobile = useMediaQuery('(min-width:600px)')

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    async function getItems() {
        const items = await fetch(
            `${process.env.REACT_APP_SERVER_BASE_URL}/api/items?populate=image&populate=inventory`,
            { method: 'GET' }
        )
        const itemsJson = await items.json()
        dispatch(setItems(itemsJson.data))
    }

    useEffect(() => {
        getItems()
    }, [])


    const trufaFrescaItems = items.filter(
        (item) => item.attributes.category === 'trufaFresca'
    )

    const salsasTrufaItems = items.filter(
        (item) => item.attributes.category === 'salsasConTrufa'
    )

    const salsasItems = items.filter(
        (item) => item.attributes.category === 'salsas'
    )

    const condimentosItems = items.filter(
        (item) => item.attributes.category === 'condimentos'
    )

    const pastaItems = items.filter(
        (item) => item.attributes.category === 'pasta'
    )

    const paraPicarItems = items.filter(
        (item) => item.attributes.category === 'paraPicar'
    )

    const dulcesItems = items.filter(
        (item) => item.attributes.category === 'dulces'
    )

    const masVendidosItems = items.filter(
        (item) => item.attributes.category === 'masVendidos'
    )

    const herramientasItems = items.filter(
        (item) => item.attributes.category === 'herramientas'
    )

    const quesosEmbutidosItems = items.filter(
        (item) => item.attributes.category === 'quesosYEmbutidos'
    )

    return (
        <Box width='80%' margin='8px auto'>
            <Typography fontFamily='Gluten' variant='h2' textAlign='center' padding='5px 0px'>
                MERCADILLO
            </Typography>
            <Typography fontSize='23px' textAlign='center' padding='0px 0px' marginTop="-10px" sx={{textTransform: 'capitalize'}}>
                Tu tienda de trufa.
            </Typography>
            <Tabs
                textColor='primary'
                indicatorColor='primary'
                value={value}
                onChange={handleChange}
                centered
                TabIndicatorProps={{ sx: { display: 'none'} }}
                sx={{
                    m: '25px',
                    '& .MuiTabs-flexContainer': {
                        flexWrap: 'wrap'
                    }
                }}
            >
                <Tab label={<Typography fontSize='18px'>ALL</Typography>} value='all' />
                <Tab label={<Typography fontSize='18px'>TRUFA FRESCA</Typography>} value='trufaFresca' />
                <Tab label={<Typography fontSize='18px'>SALSAS CON TRUFA</Typography>} value='salsasConTrufa' />
                <Tab label={<Typography fontSize='18px'>SALSAS</Typography>} value='salsas' />
                <Tab label={<Typography fontSize='18px'>CONDIMENTOS</Typography>} value='condimentos' />
                <Tab label={<Typography fontSize='18px'>PASTA</Typography>} value='pasta' />
                <Tab label={<Typography fontSize='18px'>PARA PICAR</Typography>} value='paraPicar' />
                <Tab label={<Typography fontSize='18px'>DULCES</Typography>} value='dulces' />
                <Tab label={<Typography fontSize='18px'>MAS VENDIDOS</Typography>} value='masVendidos' />
                <Tab label={<Typography fontSize='18px'>QUESOS Y EMBUTIDOS</Typography>} value='quesosYEmbutidos' />
                <Tab label={<Typography fontSize='18px'>HERRAMIENTAS</Typography>} value='herramientas' />
            </Tabs>
            <Box
                margin='0 auto'
                display='grid'
                gridTemplateColumns='repeat(auto-fill, 300px)'
                justifyContent='space-around'
                rowGap='20px'
                columnGap='1.33%'
            >
                {value === 'all' && items.map((item) => (
                    <Item item={item} key={`${item.name}-${item.id}`} />
                ))}
                {value === 'trufaFresca' && trufaFrescaItems.map((item) => (
                    <Item item={item} key={`${item.name}-${item.id}`} />
                ))}
                {value === 'salsasConTrufa' && salsasTrufaItems.map((item) => (
                    <Item item={item} key={`${item.name}-${item.id}`} />
                ))}
                {value === 'salsas' && salsasItems.map((item) => (
                    <Item item={item} key={`${item.name}-${item.id}`} />
                ))}
                {value === 'condimentos' && condimentosItems.map((item) => (
                    <Item item={item} key={`${item.name}-${item.id}`} />
                ))}
                {value === 'pasta' && pastaItems.map((item) => (
                    <Item item={item} key={`${item.name}-${item.id}`} />
                ))}
                {value === 'paraPicar' && paraPicarItems.map((item) => (
                    <Item item={item} key={`${item.name}-${item.id}`} />
                ))}
                {value === 'dulces' && dulcesItems.map((item) => (
                    <Item item={item} key={`${item.name}-${item.id}`} />
                ))}
                {value === 'masVendidos' && masVendidosItems.map((item) => (
                    <Item item={item} key={`${item.name}-${item.id}`} />
                ))}
                {value === 'quesosYEmbutidos' && quesosEmbutidosItems.map((item) => (
                    <Item item={item} key={`${item.name}-${item.id}`} />
                ))}
                {value === 'herramientas' && herramientasItems.map((item) => (
                    <Item item={item} key={`${item.name}-${item.id}`} />
                ))}
            </Box>
        </Box>
    )
}

export default ShoppingList